<!--
 * @Author: DY
 * @Date: 2020-10-22 11:00:44
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-14 13:50:05
 * @Describe:
-->
<template>
    <div class="pullWaterForm">
        <div v-if="isEdit == false">
            <h4>车号</h4>
            <div>
                <el-input
                    v-model="plate_small_number"
                    readonly
                >
                    <el-button slot="append" @click="clickSelectWaitCar()">
                        选择
                    </el-button>
                </el-input>
            </div>
        </div>
        <div>
            <h4>方量</h4>
            <div>
                <el-input
                    placeholder="请输入内容"
                    v-model="Volume"
                    clearable
                    type="number"
                    @mousewheel.native.prevent
                >
                </el-input>
            </div>
        </div>
        <div>
            <h4>费用</h4>
            <div>
                <el-input
                    placeholder="请输入内容"
                    v-model="cost"
                    clearable
                    type="number"
                    @mousewheel.native.prevent
                >
                </el-input>
            </div>
        </div>
        <footer>
            <el-button @click="cancel">
                取消
            </el-button>
            <el-button type="primary" @click="confirm">
                确认
            </el-button>
        </footer>
    </div>
</template>
<script>
import store from '@/store';

export default {
    // 拉水
    name: 'pull-water-form',
    components: {},
    props: {
        extr: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            // 车号
            plate_small_number: '',
            // 方量
            Volume: 0,
            // 费用
            cost: 200,
            // 选中更换车辆
            selectedVehicles: {},
            // 是否是编辑
            isEdit: false,
        };
    },
    watch: {},
    activated() {},
    created() {
        if (this.extr.pull_water_number) {
            this.isEdit = true;
            // 编辑初始化拉水
            this.initDrawWater();
        } else {
            this.isEdit = false;
        }

    },
    mounted() {},
    methods: {
        // 编辑初始化拉水
        initDrawWater() {
            this.$axios
                .get(`/interfaceApi/production/pullwater/load/${this.extr.pull_water_number}`)
                .then(res => {
                    this.Volume = res.volume;
                    this.cost = res.amount;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 关闭拉水弹出层
        cancel() {
            this.$parent.hide();
            this.extr.closeCallback();
        },
        // 拉水确认
        confirm() {
            if (this.isEdit) {
                // 修改拉水
                this.editorPullWater();
            } else {
                // 添加拉水
                this.addPullWater();
            }
            this.extr.sureCallback();
        },
        // 添加拉水
        addPullWater() {
            this.$axios
                .post('/interfaceApi/production/pullwater/add', {
                    task_number: this.extr.radioTaskChenked,
                    plate_number: this.selectedVehicles.plate_number,
                    volume: this.Volume,
                    amount: this.cost,
                    remarks: '',
                })
                .then(() => {
                    this.$message({
                        message: '添加成功！',
                        type: 'success',
                    });
                    this.$parent.hide();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 修改拉水
        editorPullWater() {
            this.$axios
                .post('/interfaceApi/production/pullwater/modify', {
                    pull_water_number: this.extr.pull_water_number,
                    task_number: this.extr.radioTaskChenked,
                    volume: this.Volume,
                    amount: this.cost,
                })
                .then(() => {
                    this.$message({
                        message: '修改成功！',
                        type: 'success',
                    });
                    this.$parent.hide();
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 打开选择车辆弹出层
        clickSelectWaitCar() {
            this.$toast({
                title: true,
                text: '选择车辆',
                type: 'eject',
                width: '14rem',
                height: '8rem',
                t_url: 'productionManagement/productionDelivery/pullWaterForm/selectVehicle',
                extr: {
                    wait_car_list: JSON.parse(JSON.stringify(this.extr.vehicleData)),
                    WaitCarActive: this.plate_small_number,
                    sureCallback: this.selectSelectWaitCarCallback,
                },
            });
        },
        // 选择车辆回调
        selectSelectWaitCarCallback(data) {
            // 选中车辆
            this.selectedVehicles = data;
            // 选中车小号
            this.plate_small_number = data.plate_small_number;
        },
    },
    filter: {},
    computed: {},
};
</script>

<style lang="stylus">
.pullWaterForm
    height 100%
    padding 0.2rem
    position relative
    >div
        display flex
        margin-bottom 0.2rem
        h4
            flex 1
            text-align center
            font-size 0.16rem
            line-height 0.36rem
        >div
            flex 9
    footer
        position absolute
        bottom 0.3rem
        left 0
        width 100%
        text-align center
        button
            width 1.4rem
            margin 0 0.2rem
</style>
